import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Certificate } from "./records";

@Injectable({
  providedIn: "root",
})
export class ApiServiceService {
  constructor(private httpService: HttpClient) {}

  fetchCertificateDetails() {
    //URL to fetch
    let FETCHURL = "https://prashantranjan.com/api/fetch_certification.php";

    // Fetch info
    return this.httpService.get<Certificate[]>(FETCHURL);
  }
}
