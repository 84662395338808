import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { AddStudentComponent } from "./add-student/add-student.component";
import { FetchStudentComponent } from "./fetch-student/fetch-student.component";
import { HomeComponent } from "./home/home.component";
import { ContactComponent } from "./contact/contact.component";

// Importing Router Module
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgxPaginationModule } from "ngx-pagination";
import { CertificationsComponent } from "./certifications/certifications.component";
import { BlogComponent } from "./blog/blog.component";
import { VideosComponent } from "./videos/videos.component";
import { ServiceInfoComponent } from "./service-info/service-info.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    NavbarComponent,
    //AddStudentComponent,
    //FetchStudentComponent,
    HomeComponent,
    ContactComponent,
    CertificationsComponent,
    BlogComponent,
    VideosComponent,
    ServiceInfoComponent,
  ],

  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    // Routes Registeration will be inside imports
    RouterModule.forRoot([
      { path: "contact", component: ContactComponent },
      { path: "videos", component: VideosComponent },
      { path: "blogs", component: BlogComponent },
      { path: "certifications", component: CertificationsComponent },
      { path: "", component: HomeComponent },
      { path: "**", component: HomeComponent },
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
