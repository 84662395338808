import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ApiServiceService } from "../api-service.service";
import { Certificate } from "../records";

@Component({
  selector: "app-certifications",
  templateUrl: "./certifications.component.html",
  styleUrls: ["./certifications.component.css"],
})
export class CertificationsComponent implements OnInit {
  receivedCertificates: Observable<Certificate[]>;

  constructor(private webSrv: ApiServiceService) {
    this.receivedCertificates = webSrv.fetchCertificateDetails();
  }

  ngOnInit() {}
}
